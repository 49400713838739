







import React, { useEffect } from 'react'

function Policies() {

    useEffect(() => {
        document.title = "Policies"

    },[])


  return (
    <div className='policies-container'>
       <h1>Política de privacidad</h1> 
      <p>

<h4>
Última actualización: 16 de mayo de 2024
</h4>
<p>
Esta Política de Privacidad describe cómo se recopila, utiliza y comparte la información personal de los usuarios que utilizan la extensión "Dropi Expert".</p>

</p>
<div>

<h2>Información que recopilamos</h2>
<h4>Cuando los usuarios utilizan nuestra extensión, podemos recopilar y almacenar la siguiente información:</h4>

    <ul>
        <li>Correo electrónico: Recopilamos la dirección de correo electrónico proporcionada por el usuario para facilitar el inicio y cierre de sesión en nuestra extensión.</li>
        <li>Contraseña encriptada: Para garantizar la seguridad de la cuenta del usuario, recopilamos y almacenamos una versión encriptada de la contraseña del usuario.</li>
    </ul>



</div>
<div>
    <h2>
Uso de la información
</h2>


<h4>La información recopilada se utiliza únicamente para los siguientes propósitos:</h4>


<ul>
    <li>Facilitar el inicio y cierre de sesión del usuario en nuestra extensión.</li>
    <li>Mantener la seguridad de la cuenta del usuario mediante el almacenamiento seguro de la contraseña encriptada.</li>

</ul>




<h2>
Compartir información</h2>

<h4>No compartimos la información personal recopilada con terceros, excepto en los siguientes casos:</h4>

<ul>

    <li>Cuando sea necesario para cumplir con la ley o proteger los derechos, la propiedad o la seguridad de nuestra extensión, de nuestros usuarios o de otros.
Seguridad de la información.</li>
<li>Nos comprometemos a proteger la información personal de nuestros usuarios y hemos implementado medidas de seguridad físicas, electrónicas y administrativas para salvaguardarla.</li>
<li></li>
</ul>




<h2>Cambios en la política de privacidad</h2>
<p>
Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Se alentará a los usuarios a revisar esta página periódicamente para estar al tanto de cualquier cambio. El uso continuado de la extensión después de la publicación de cualquier cambio en esta política de privacidad constituirá la aceptación de dichos cambios.

Contacto

Si los usuarios tienen alguna pregunta o inquietud sobre esta política de privacidad, pueden ponerse en contacto con nosotros en ema62x@gmail.com.</p>

</div>
    </div>
  )
}

export default Policies
