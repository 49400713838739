
import { useEffect, useState } from 'react';
import './App.css';
import { useSearchParams } from 'react-router-dom';

function App() {

  const [token, setToken] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
      let userToken =searchParams.get("id")

      setToken(userToken)
      

    }, [])

  return (
    <div className="App">
      <header className="App-header">
      
      </header>
    </div>
  );
}

export default App;
