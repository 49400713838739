import { useEffect, useState } from "react";
import "../App.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { uuid } from "uuidv4";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
// initMercadoPago("TEST-27657ac4-3b38-424e-81f2-dcb9acf0dd8d", {
initMercadoPago("APP_USR-8df652ef-7f1a-460c-81d4-2910883942ca", {
locale: "es-AR",
});

function Checkout() {
  const [token, setToken] = useState("");
  const [clientConected, setClientConected] = useState({});
  const [referencId, setReferencId] = useState("");
  const [amount, setAmount] = useState(0);
  const [meses, setMeses] = useState("");

  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  function generateString(length) {
      let result = ' ';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
  
      return result;
  }


  const getClient = () => {
    axios
      .get("https://dropi-expert.cloud/api/clients/current", {
        headers: { Authorization: token },
      })
      .then((response) => {
        setClientConected(response.data);
        // console.log(response.data);
      });
  };

  const getReferenceId = () => {
    axios
      .post(
        "https://dropi-expert.cloud/api/mp?email="+clientConected.email,
        { plan: searchParams.get("plan") },
        { headers: { Authorization: token, "X-Idempotency-Key": generateString() } }
      )
      .then((response) => {

        axios.post("https://dropi-expert.cloud/api/payment?email="+clientConected.email, null, { headers: { Authorization: token } })
        .then((response) => {
          
        })


        console.log(response.data);
        setReferencId(response.data.id);
      });
  };

  const handlerPriceAmount =  (plan) => {
      console.log(plan);
      switch (plan) {
        case "Mensual":
          setAmount(60000);
          setMeses("1 mes")
          break;
        case "Semestral":
          setAmount(270000);
          setMeses("6 meses")

          break;
        case "Anual":
          setAmount(360000);
          setMeses("12 meses")

          break;
        // case "INACTIVE":
        //   setAmount(60000);
        // break;
        // case "Founder":
        //   setAmount(30000);
        // break;    
        default:
          break;
      }
      
      if(clientConected.plan == "FOUNDER") {
        setAmount(30000);
          setMeses("1 mes")
      }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    document.title = "Checkout"
    let userToken = searchParams.get("id");

    // if(!userToken){
    //   window.location.href = "http://localhost:3000/"
    // }
    // if(!plan){
    //   window.location.href = "http://localhost:3000/"
    // }

    setToken(userToken);
  }, []);

  useEffect(() => {
    if (token != "") {
      getClient();
    }
  }, [token]);

  useEffect(() => {
    if (clientConected.plan) {
    let plan = searchParams.get("plan");
      
      handlerPriceAmount(plan);
      getReferenceId();
    }
  }, [clientConected]);

  return (
    <div className="App">
      <header className="App-header">
        {/* <div id="wallet_container"></div> */}
        <div className="checkout-container">
          <img src={require("../assets/icon.png")} alt="" />
          <h1 className="title">Checkout</h1>

          <div className="info-container">
            <p>Monto a pagar ${amount}</p>
            <p>Plan de {meses}</p>
          </div>

          {referencId != "" && (
            <Wallet
              initialization={{ preferenceId: referencId }}
              customization={{ texts: { valueProp: "smart_option" } }}
            />
          )}
        </div>
      </header>
    </div>
  );
}

export default Checkout;
